import React from 'react';
import { useSelector } from 'react-redux';
import { useStyletron } from 'baseui';
import { Notification, KIND } from 'baseui/notification';
import { isMnAEmployeeSelector } from 'newHirePortal/newHire/store/user/selectors';
import Item from '../../../components/Item';

const ListItem = ({ children, color }) => (
  <Item paddingTop='5px' paddingBottom='5px' minHeight='46px' color={color}>
    {children}
  </Item>
);

const Messages = () => {
  const [css] = useStyletron();
  const isMnAEmployee = useSelector(isMnAEmployeeSelector);

  return (
    <ul
      className={css({
        width: '100%',
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: 0
      })}
    >
      <ListItem>
        Please complete all onboarding tasks listed on the right-hand side of
        the screen under “Task List” prior to your start date (Day 1).
      </ListItem>
      {isMnAEmployee && (
        <Notification
          title='shipping notification'
          kind={KIND.warning}
          overrides={{
            InnerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.black
              })
            },
            Body: {
              style: ({ $theme }) => ({
                width: '75%',
                marginTop: '0',
                marginLeft: '16px',
                borderBottomRightRadius: $theme.borders.radius200,
                borderBottomLeftRadius: $theme.borders.radius200,
                borderTopRightRadius: $theme.borders.radius200,
                borderTopLeftRadius: $theme.borders.radius200,
                backgroundColor: $theme.colors.mnaNotification,
                paddingLeft: $theme.sizing.scale800
              })
            }
          }}
        >
          <strong>PRIORITY:</strong> Please make sure you fill out your shipping
          address within the next <b>48 hours.</b>
        </Notification>
      )}
      <ListItem>
        Credentials for your Workday login are coming your way{' '}
        {isMnAEmployee
          ? 'via the same email used for offer letters'
          : 'via email'}
        . <strong>Please note:</strong> your username and password are sent
        separately. After you complete all workday tasks, your onboarding access
        will expire. You’ll receive employee access on your first day with your
        corporate credentials.
      </ListItem>
      <ListItem>
        Once your IT equipment is ordered, our vendor will email you a tracking
        number so you know when to expect its arrival!
      </ListItem>
    </ul>
  );
};

export default Messages;
