import React from 'react';
import InfoWrapper from 'newHirePortal/newHire/components/InfoWrapper';
import Messages from './Messages';

const BeforeDayOne = () => {
  const dayOneMsg = 'Before Day 1';
  return (
    <InfoWrapper
      Header={dayOneMsg}
      ModalHead={dayOneMsg}
      Modalcontent={<Messages />}
      height='auto'
    >
      <Messages />
    </InfoWrapper>
  );
};
export default BeforeDayOne;
