import { styled, useStyletron } from 'baseui';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ParagraphLarge } from 'baseui/typography';
import { isTidalTheme } from 'newHirePortal/newHire/store/user/helpers';
import MsgComp from './MsgComp';
import { LABELS, JACK_CONTENT, getCoreMemberMsg } from '../../util/coreMemMsg';
import { getCoreMember } from '../../store/coreMembers/thunk';
import { NewlineText, SmallMessage } from '../../util/dataParseHelpers';
import { API_STATUS } from '../../util/enums';
import SquareOneVideo from '../../components/SquareOneVideo';
import LoadingIndicator from '../../components/LoadingIndicator';
import JackDorsey from '../../../../assets/images/jackDorsey.jpeg';

const RowWrapper = styled('div', () => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  padding: '30px 0'
}));

const ImgWrapper = styled('img', () => ({
  width: '88px',
  height: '88px',
  borderRadius: '50%',
  marginTop: '-55px'
}));

const MsgBanner = () => {
  let content;
  let member;
  const [css, theme] = useStyletron();
  const [containerWidth, setcontainerWidth] = useState('44%');
  const dispatch = useDispatch();
  const coreMemberLoadStatus = useSelector(
    (state) => state.coreMember.loadStatus
  );
  const coreMemberData = useSelector((state) => state.coreMember.data);

  const Wrapper = styled('div', ({ $theme }) => ({
    width: containerWidth,
    height: 'auto',
    border: `1px solid ${$theme.colors.mono300}`,
    padding: '20px 24px',
    position: 'relative',
    borderRadius: '6px'
  }));

  useEffect(() => {
    if (coreMemberLoadStatus === API_STATUS.IDLE) {
      dispatch(getCoreMember());
    }
  }, [coreMemberLoadStatus]);

  if (coreMemberLoadStatus === API_STATUS.LOADING) {
    content = <LoadingIndicator width='50%' />;
  }

  if (coreMemberLoadStatus === API_STATUS.COMPLETED) {
    member = getCoreMemberMsg(coreMemberData, theme.name);
    if (!member && containerWidth !== '100%') {
      setcontainerWidth('100%');
    }
    content = member ? (
      <Wrapper>
        <MsgComp
          Heading={`${LABELS.WELCOME_MSG}${coreMemberData.first_name} ${
            isTidalTheme(theme) ? '' : coreMemberData.last_name
          }`}
          ModalHeading={`${LABELS.WELCOME_MSG}${coreMemberData.first_name}`}
          ModalMessage={NewlineText(member.message)}
          SmallMsg={`${SmallMessage(member.message)}...`}
          MemName={`${coreMemberData.first_name} ${coreMemberData.last_name}`}
          designation={member.designation}
          contentHeight='256px'
          ModImg={
            <ImgWrapper
              src={`data:image/png;base64,${coreMemberData.profile_image}`}
              alt='core_mem'
            />
          }
        />
      </Wrapper>
    ) : (
      <></>
    );
  } else if (coreMemberLoadStatus === API_STATUS.FAILED) {
    content = <></>;
    if (containerWidth !== '100%') {
      setcontainerWidth('100%');
    }
  }

  return (
    <>
      <RowWrapper>
        <Wrapper
          className={css({
            marginRight: '8px'
          })}
        >
          <MsgComp
            Heading={JACK_CONTENT.JACK_DORSEY}
            ModalHeading={`${JACK_CONTENT.JACK_DORSEY}:`}
            ModalMessage={
              <ParagraphLarge>{JACK_CONTENT.MOD_MSG}</ParagraphLarge>
            }
            SmallMsg={JACK_CONTENT.MOD_MSG}
            ModVideo={SquareOneVideo}
            contentHeight='24px'
            ModImg={<ImgWrapper src={JackDorsey} alt=' jack' />}
          />
        </Wrapper>
        {content}
      </RowWrapper>
    </>
  );
};

export default MsgBanner;
