import React from 'react';
import { useSelector } from 'react-redux';
import { ProgressBar } from 'baseui/progress-bar';
import { styled, useStyletron } from 'baseui';
import {
  ParagraphLarge,
  ParagraphSmall,
  DisplaySmall
} from 'baseui/typography';
import { useHistory } from 'react-router-dom';
import {
  nextPendingTaskSelector,
  taskCompletePercentageSelector
} from 'newHirePortal/newHire/store/user/selectors';
import AccentButton from 'newHirePortal/newHire/components/AccentButton';
import { getPathByName } from 'newHirePortal/newHire/util/routes';
import { THEME_NAME } from 'newHirePortal/newHire/util/enums';
import crow from '../../../../assets/images/crow.png';

const Wrapper = styled('div', ({ $theme }) => ({
  boxShadow: $theme.shadow.shadowStatic,
  borderRadius: '8px',
  position: 'relative',
  overflow: 'hidden',
  padding: '24px',
  display: 'block'
}));

const Buttonwrap = styled('div', ({ $theme }) => ({
  display: 'flex',
  marginTop: $theme.name === THEME_NAME.CASH ? '-55px' : '-20px',
  flexDirection: 'column',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  alignItems: 'center',
  alignContent: 'flex-end'
}));

const Progress = () => {
  const history = useHistory();
  const userData = useSelector((state) => state.user.data);
  const percentage = useSelector(taskCompletePercentageSelector);
  const nextTask = useSelector(nextPendingTaskSelector);
  const [, theme] = useStyletron();

  const ButtonCompo = (
    <AccentButton
      label='Start here'
      width='109px'
      marginTop='0'
      onClick={() => history.push(getPathByName(nextTask.name))}
    />
  );

  return (
    <Wrapper>
      <DisplaySmall>
        {userData.first_name} {userData.last_name}, Welcome to{' '}
        {theme.displayName}!
      </DisplaySmall>
      <ParagraphLarge>
        This portal will help you get setup and prepare for your first day.
      </ParagraphLarge>
      <ProgressBar
        value={percentage}
        successValue={100}
        overrides={{
          BarContainer: {
            style: { marginLeft: 0, width: '60%' }
          },
          BarProgress: {
            style: ({ $theme }) => ({
              backgroundColor: $theme.colors.contentAccent
            })
          },
          Bar: {
            style: { height: '8px' }
          }
        }}
      />
      <ParagraphSmall>{percentage}% set up</ParagraphSmall>
      {nextTask ? (
        <Buttonwrap>
          {theme.name === THEME_NAME.CASH ? (
            <>
              <img src={crow} alt='crow' />
              {ButtonCompo}
            </>
          ) : (
            <>{ButtonCompo}</>
          )}
        </Buttonwrap>
      ) : null}
    </Wrapper>
  );
};
export default Progress;
