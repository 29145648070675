import React from 'react';
import { ParagraphLarge } from 'baseui/typography';
import { BU_NAME } from './enums';

const createMessage = (name, id, buName, designation) => ({
  name,
  id,
  buName,
  designation,
  message:
    'On behalf of the organization, welcome to Block! ' +
    'We are so excited to have you join our team. Our mission is to enable bold growth at scale, through functional excellence, operational partnership and strategic thoughtfulness. ' +
    'We do this by putting people first, striving for agility, enabling the organization to take principled risks, and driving new growth opportunities. ' +
    'We are excited to have you join the team to help us push this mission forward! ' +
    'I look forward to working with you and am around if there is anything I can help you with.\n' +
    'Best,'
});

// TODO Confirm Core's BU

export const CORE_MEM_MSG = [
  {
    name: 'Steve Lee',
    id: '009436',
    designation: '',
    message: 'Welcome to Block!\nBest,',
    buName: BU_NAME.BLOCK
  },

  {
    name: 'Brian Grassadonia',
    id: '000134',
    buName: BU_NAME.CASH,
    designation: 'CEO, Cash App',
    message: (
      <ParagraphLarge>
        Welcome to Cash. I’m thrilled that you’ve decided to join our team. No
        matter what your role is, our mission is the same: redefine the world’s
        relationship with money by making it more relatable, instantly available
        and universally accessible. We do this when we
        <i>
          {' '}
          Insist on Focus, Compete on Creativity, Feel it, Climb Walls and Fight
          Complacency
        </i>
        . That’s a lot of words, but I think you’ll find that these principles
        come naturally to you. I’m looking forward to seeing the positive impact
        you’ll make and the incredible things we’ll create together.
        <ParagraphLarge>Best,</ParagraphLarge>
      </ParagraphLarge>
    )
  },
  createMessage('Amrita Ahuja', '008623', BU_NAME.BLOCK, 'COO & CFO'),
  createMessage('Amrita Ahuja', '008623', BU_NAME.CASH, 'COO & CFO'),
  createMessage('Amrita Ahuja', '008623', BU_NAME.TIDAL, 'COO & CFO'),
  createMessage('Amrita Ahuja', '008623', BU_NAME.SQ_GENERAL, 'COO & CFO'),
  {
    name: 'Helena Christianson',
    id: '003864',
    buName: BU_NAME.BLOCK,
    designation: '',
    message:
      'Welcome to the People team. We intentionally call our team “People” instead of ' +
      '“HR” – it sounds way more friendly (yay!) and it also better reflects the broad spectrum of things we do at Block. ' +
      'Whether you’ll recruit our new colleagues, build tools to make processes like healthcare enrollment and ' +
      'performance management less process-y, or develop programs to help people grow their own careers, ' +
      'you’re on the front lines of the company. I know you’re going to want to dig in fast, but be sure to take time to observe and ' +
      'meet as many people as you can. You will never be less busy than in your first few weeks – take advantage of the opportunity.\n' +
      'I encourage you to bring feedback and ideas to your lead, to me, or to each other so we can be even better by the time the next ' +
      'folks receive this fully automated but hopefully humanizing note.\n' +
      'Enjoy the ride!\nBest,'
  }
];

export const getCoreMemberMsg = (member, buName) => {
  const byId = CORE_MEM_MSG.find(
    (msg) => msg.id === member.employee_id && buName === msg.buName
  );
  if (byId) return byId;

  const fullName = `${member.first_name} ${member.last_name}`;
  return CORE_MEM_MSG.find(
    (msg) =>
      fullName.toLowerCase() === msg.name.toLowerCase() && buName === msg.buName
  );
};

export const JACK_CONTENT = {
  JACK: 'Jack Dorsey',
  JACK_DORSEY: 'Welcome from Jack Dorsey',
  MOD_MSG: "Watch Jack's welcome video"
};

export const LABELS = {
  SHOW_ALL: 'Show all',
  WELCOME_MSG: 'Welcome from '
};
