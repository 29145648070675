import { withStyle } from 'baseui';
import React from 'react';
import { StyledLink } from 'baseui/link';
import { datadogRum } from '@datadog/browser-rum';

const MyStyledLink = withStyle(StyledLink, ({ $theme }) => ({
  color: $theme.colors.backgroundAccent,
  textDecoration: 'inherit',
  cursor: 'pointer',
  ':visited': {
    color: $theme.colors.backgroundAccent
  }
}));

const MyGoLinks = ({ goLink, source }) => (
  <MyStyledLink
    href={source}
    target='_blank'
    onClick={() => {
      datadogRum.addAction(`click link of ${goLink}`);
    }}
  >
    {goLink}
  </MyStyledLink>
);

export default MyGoLinks;
