import React from 'react';
import { useStyletron } from 'baseui';
import Item from 'newHirePortal/newHire/components/Item';
import MyGoLinks from 'newHirePortal/newHire/components/MyGoLinks';
import Benefits from 'assets/docs/Benefits.pdf';

const SquareMessages = () => {
  const [css, theme] = useStyletron();

  return (
    <>
      <Item>
        <strong>IT Office Hours:</strong> On your start date, you will receive
        instructions via email to set up your laptop. We recommend beginning the
        setup process between 8:00-9:00am your local time on your start date.
        Please do not set up your laptop prior to your start date. Join the IT
        Office Hours on Day 1 if you need technical support from a member of our
        IT Team.
      </Item>
      <Item>
        <strong>Onboarding Sessions:</strong> Onboarding will be conducted
        virtually via Google Meet video call during your first week. You’ll
        receive calendar invites to your work email on your Day 1 with specific
        times for each of the below sessions:
      </Item>
      <div className={css({ marginLeft: '40px' })}>
        <Item color={theme.colors.accent50}>
          <strong>Building Block Orientation</strong> - This live session will
          walk you through our company’s history, purpose, and culture. You’ll
          have the opportunity to make new connections with other new hires.
          Building Block runs on Day 1 for those located in North America and
          EMEA regions and Day 2 for those located in Asia Pacific regions.
        </Item>
        <Item color={theme.colors.accent50}>
          <strong>Ask Me Anything Session with Jack Dorsey</strong> - In this
          live session you will have the opportunity to meet with the Head of
          Block, Jack Dorsey, and ask him your top-of-mind questions.
        </Item>
        <Item color={theme.colors.accent50}>
          <strong>New Hire Equity Session</strong> - This live session will give
          you an overview of RSUs (restricted stock units), ESPP, and all things
          related to Block’s Equity Program. This session runs monthly.
        </Item>
        <Item color={theme.colors.accent50}>
          <strong>Note on additional onboarding sessions</strong> - Depending on
          your team/role, you may also receive additional calendar invites for
          function-specific orientation sessions.
        </Item>
      </div>
      <Item>
        <strong>Benefits:</strong> You will receive a new hire benefits task in
        your Workday inbox on Day 2 or Day 3. You will have 60 days from your
        start date to complete this task.
      </Item>
      <Item>
        <strong>
          This portal will expire in 10 days after your start date
        </strong>
        , at which point,{' '}
        <span>
          <MyGoLinks goLink='go/onboarding ' source='http://go/$newhire' />
        </span>
        will be your hub for all things onboarding.Note: this go/link
        (go/onboarding) will work for you once you have your corporate login
        credentials on your start date.
      </Item>
      <Item>
        Finally, <strong>check out this helpful resource:</strong>{' '}
        <span>
          <MyGoLinks goLink='Pre-Onboarding Benefits FAQ ' source={Benefits} />
        </span>
        for more details on Block’s benefits, badge request, offices, payroll,
        Right-to-Work, background screening process, IT/Laptop Self-Service
        Onboarding, laptop equipment delay help, and more!
      </Item>
    </>
  );
};

export default SquareMessages;
