import React from 'react';
import InfoWrapper from 'newHirePortal/newHire/components/InfoWrapper';
import WeekOneMsgs from './WeekOneMsgs';

const WeekOne = () => {
  const weekOneMsg = 'Week 1';

  return (
    <>
      <InfoWrapper
        Header={weekOneMsg}
        ModalHead={weekOneMsg}
        Modalcontent={<WeekOneMsgs />}
        height='auto'
      >
        <WeekOneMsgs />
      </InfoWrapper>
    </>
  );
};
export default WeekOne;
